import React from 'react';
import SpecialistCard from "./SpecialistCard";
import {graphql, useStaticQuery} from "gatsby";

const SpecialistsList = () => {
  const data = useStaticQuery(graphql`
    query personalQuery {
      allPersonalCsv {
        nodes {
          img
          name
          title
        }
      }
      allImageSharp(filter: {fluid: {originalName: {regex: "/personal/"}}}) {
        nodes {
          gatsbyImageData
          fixed {
            originalName
          }
        }
     }
    }
  `)
  const personal = data.allPersonalCsv.nodes
  const personalImages = data.allImageSharp.nodes

  return (
  <div className="container specialists-list">
    <div className="row">
      {personal.map((person) => (
        <div className="col-12 col-md-3 mb-5" key={person.name}>
          <SpecialistCard person={person} image={personalImages.find((img) => img.fixed.originalName === person.img)}/>
        </div>
      ))}
    </div>
  </div>
)
};

export default SpecialistsList;