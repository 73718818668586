import React from 'react';
import Title from "../Common/Title";

const SpecialistsInfo = () => (
  <div className="panel my-5">
    <section className="current specialists-info">
      <div className="container">
        <div className="row text-center">
          <div className="col-sm-12 col-md-12 col-md-12">
            <div>
              <Title>Спеціалісти</Title>
            </div>
            <p>
              За роки роботи медичної установи лікарям вдалося не лише впровадити в
              практику багато методик у флебології, дерматохірургії, проктології, гінекології, а й
              <span className="highlight">заслужити довіру пацієнтів і колег з усієї України.</span>
            </p>
          </div>
        </div>
        {/*<div className="row">*/}
        {/*  <div className="col-md-offset-1 col-sm-12 col-md-12 col-md-10">*/}
        {/*    <div className="services-list">*/}
        {/*      <div className="row">*/}
        {/*        <div className="col-sm-6 col-md-4 col-md-4">*/}
        {/*          <div className="service-block">*/}
        {/*            <div className="text-block">*/}
        {/*              <div className="name">Web Design</div>*/}
        {/*              <div className="info">Beauty and function</div>*/}
        {/*              <div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque*/}
        {/*                habitant morbi tristique senectus et netus et malesuada fames*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*        <div className="col-sm-6 col-md-4 col-md-4">*/}
        {/*          <div className="service-block">*/}
        {/*            <div className="text-block">*/}
        {/*              <div className="name">Development/Coding</div>*/}
        {/*              <div className="info">Quality code that lasts</div>*/}
        {/*              <div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque*/}
        {/*                habitant morbi tristique senectus et netus et malesuada fames*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*        <div className="col-sm-6 col-md-4 col-md-4">*/}
        {/*          <div className="service-block">*/}
        {/*            <div className="text-block">*/}
        {/*              <div className="name">Copy Writing</div>*/}
        {/*              <div className="info">Words that tell your story</div>*/}
        {/*              <div className="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque*/}
        {/*                habitant morbi tristique senectus et netus et malesuada fames*/}
        {/*              </div>*/}
        {/*            </div>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </section>
  </div>
);

export default SpecialistsInfo;