import React from 'react';
import Seo from "../components/seo";
import Layout from "../components/layout";
import SpecialistsInfo from "../components/Specialists/SpecialistsInfo";
import SpecialistsList from "../components/Specialists/SpecialistsList";

const Specialists = ()=> (
  <Layout>
    <Seo title="Спеціалісти"/>
    <SpecialistsInfo/>
    <SpecialistsList/>
  </Layout>
);

export default Specialists;