import React from 'react';
import {GatsbyImage} from "gatsby-plugin-image";
import {getImageAltText} from "../../utils/common";

const SpecialistCard = ({person, image}) => (
  <div className="panel">
    <div className="card">
      <GatsbyImage image={image.gatsbyImageData} className="card-img-top" placeholder="blurred" alt={getImageAltText()}/>
        <div className="card-body">
          <h5 className="card-title">{person.name}</h5>
          <p className="card-text">{person.title}</p>
          {/*<button type="button" className="btn btn-sm float-end" data-bs-toggle="modal" data-bs-target="#Modal">Залишити відгук</button>*/}
        </div>
    </div>
  </div>
);

export default SpecialistCard;
